import React from 'react';

import ResetPassword from '../../components/views/profile/resetPassword';
import { LayoutLoggedIn } from '../../components/views/layout';

export default function() {
    return (
        <LayoutLoggedIn>
            <ResetPassword />
        </LayoutLoggedIn>
    );
}